import Service from "../Service";

const resource = "referrealguide/";

export default {
    save(art, requestID) {
        return Service.post(resource + "save", art, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        console.log('parametro', parameters)
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    detail(parameters, requestID) {
        return Service.post(resource + "detail", parameters, {
            params: { requestID: requestID },
        });
    },

    relateddocuments(parameters, requestID) {
        return Service.post(resource + "relateddocuments", parameters, {
            params: { requestID: requestID },
        });
    },

    vehicles(parameters, requestID) {
        return Service.post(resource + "vehicles", parameters, {
            params: { requestID: requestID },
        });
    },

    drivers(parameters, requestID) {
        return Service.post(resource + "drivers", parameters, {
            params: { requestID: requestID },
        });
    },

    urlSunat(guide, requestID) {
        return Service.post(resource + "urlSunat", guide, {
            params: { requestID: requestID },
        });
    }, 

    documentPdf(parameters, requestID) {
        return Service.post(resource + "documentFacele", parameters, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },

    generateXml(parameters, requestID) {
        console.log('parametro', parameters)
        return Service.post(resource + "generateXml", parameters, {
            params: { requestID: requestID },
        });
    },

    xmlSunat(guide, requestID) {               
        return Service.post(resource + "xmlSunat", guide, {
            responseType: "blob", // Recibir el XML como Blob           
            params: { requestID: requestID },
        });
    },

    showPdf(parameters, requestID) {
        return Service.post(resource + "showPdf", parameters, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },

    updatestatefacele(parameters, requestID, RegNumber) {
        return Service.post(resource + "updatestatefacele", parameters, {
            params: { requestID: requestID, RegNumber },
        });
    },

    updatestatesunat(guide, requestID, RegNumber) {
        return Service.post(resource + "updatestatesunat", guide, {
            params: { requestID: requestID, RegNumber },
        });
    },

    refresh( requestID) {
        return Service.post(resource + "refresh", {}, { 
            params: { requestID: requestID },
        });
    },

};